import logo from './assets/images/logo/logo.svg';
import eiffel_tower from './assets/images/illustration/eiffel_tower.svg';
import pointBg from './assets/images/illustration/point-bg.svg';
import picture_profil from './assets/images/pierre-saigot.png';

import 'normalize.css';
import './assets/scss/App.scss';

function App() {
  return (
    <div className="App">
      <div className="navBar flex">
        <div className="icon">
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <div className="menu text-white flex items-center ml-auto">
          <a className="item" href="mailto:contact@pierre-saigot.com">contact@pierre-saigot.com</a>
          <a className="item ml-10 active" href="http://reboot-agency.fr/">Reboot Agency</a>
        </div>
      </div>
      <div className="header flex items-center justify-between flexResponsive">
        <div className="row relative ml-6">
          <img src={pointBg} className="pointBg z-0 absolute" />
          <div className="z-10 relative ml-4 mt-2">
            <h3>Hey there! I'm -</h3>
            <h1>Pierre Saigot</h1>
            <div className="description flex items-end">
              <p>a full-stack web developper based in </p>
              <img src={eiffel_tower} className="eiffel_tower" alt="Tour Eiffel" />
              <p>Paris</p>
            </div>
          </div>
        </div>
        <div className="row w-4/12 widthResponsive">
          <img src={picture_profil} className="photo_profil" alt="Pierre Saigot" />
        </div>
      </div>
    </div>
  );
}

export default App;
